<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="card">
			<div class="card-body">
				<a-tabs default-active-key="1">
					<a-tab-pane key="1" :tab="$t('suppliersModule.tabs.generalInfo')" value="Información General" force-render>
						<suppliersPartialsGeneral />
					</a-tab-pane>
					<a-tab-pane key="2" :tab="$t('suppliersModule.tabs.bankInfo')" value="Información Bancaria" force-render v-if="!isNewRecord">
						<suppliersPartialsBank />
					</a-tab-pane>
					<a-tab-pane key="3" :tab="$t('suppliersModule.tabs.taxInfo')" value="Información Fiscal" force-render v-if="!isNewRecord && actualRecord.general.nationality == 'national'">
						<suppliersPartialsTax />
					</a-tab-pane>
					<a-tab-pane key="4" :tab="$t('suppliersModule.tabs.emergencyContacts')" value="Contactos" force-render v-if="!isNewRecord && supplier_type == 'crew'">
						<suppliersPartialsContacts />
					</a-tab-pane>
					<!-- <a-tab-pane key="5" :tab="$t('suppliersModule.tabs.files')" value="Archivos" force-render v-if="!isNewRecord">
						<suppliersPartialsFiles />
					</a-tab-pane> -->
					<!-- <a-tab-pane key="6" :tab="$t('suppliersModule.tabs.dealMemo')" value="Deal Memo's" force-render v-if="!isNewRecord"> {{ $t('suppliersModule.tabs.dealMemo') }} </a-tab-pane> -->
					<!-- <a-tab-pane key="7" tab="Facturas emitidas" value="invoices" force-render v-if="!isNewRecord">
						<suppliersPartialsInvoices />
					</a-tab-pane>
					<a-tab-pane key="8" tab="Proyectos" value="projects" force-render v-if="!isNewRecord">
						<suppliersPartialsProjects />
					</a-tab-pane> -->
				</a-tabs>
			</div>
		</div>
	</a-spin>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import suppliersPartialsGeneral from '@/components/suppliers/partials/general'
import suppliersPartialsBank from '@/components/suppliers/partials/bank'
import suppliersPartialsTax from '@/components/suppliers/partials/tax'
import suppliersPartialsContacts from '@/components/suppliers/partials/contacts'
// import suppliersPartialsFiles from '@/components/suppliers/partials/files'
// import suppliersPartialsInvoices from '@/components/suppliers/partials/invoices'
// import suppliersPartialsProjects from '@/components/suppliers/partials/projects'

export default {
	name: 'supplierDetailView',
	components: {
		suppliersPartialsGeneral,
		suppliersPartialsBank,
		suppliersPartialsTax,
		suppliersPartialsContacts,
		// suppliersPartialsFiles,
		// suppliersPartialsInvoices,
		// suppliersPartialsProjects,
	},
	computed: {
		...mapGetters('suppliers', ['spinnerLoader', 'spinnerLoaderLabel', 'supplier_type', 'actualRecord']),
		isNewRecord() {
			return this.$route.params.id == 'new' ? true : false
		},
	},
	mounted() {
		if (utilities.objectValidate(this.$route, 'params.id', false) && !utilities.objectValidate(this.actualRecord, 'general.id', false) && !this.isNewRecord) {
			// Solicitamos carga de registro
			let id = this.$route.params.id
			this.$store.dispatch('suppliers/GET_ONE', id).then((response) => {
				this.$router.push(`/suppliers/${response.id}`)
			})
		}
	},
}
</script>